import moment from "moment-timezone";
import { combineArrays } from "src/util/combineArrays";
import { getWindowForDay, getWindowForTomorrow } from "src/util/time";
import { LOGGING, CATimeZone } from "../../constants";
import {
  ADMIN_DELIVERY_V2_SET_IS_LUNCH_FILTER,
  ADMIN_DELIVERY_V2_SET_MEAL_TYPE_FILTER,
  LOAD_ADMIN_DELIVERY_V2_DRIVERS,
  LOAD_ADMIN_DELIVERY_V2_MEALS,
  LOAD_ADMIN_DELIVERY_V2_STOPS,
  LOAD_PICKUPRESTAURANTS,
  LOAD_ROUTES,
  LOAD_START,
  RESET_START,
} from "../actionTypes";
import { apiCall } from "../api";

export const LoadPickUpRestaurants = (restaurants) => ({
  type: LOAD_PICKUPRESTAURANTS,
  restaurants,
});

export const LoadRoutes = (routes) => ({
  type: LOAD_ROUTES,
  routes,
});

export const getAdminDeliveryV2StopsForDay = () => {
  return async (dispatch, getState) => {
    LOGGING && console.log("GetStopsForWindow called");

    const now = moment().tz(CATimeZone);
    const [startAtMs, endAtMs] =
      now.hour() < 21 ? getWindowForDay() : getWindowForTomorrow();
    LOGGING && console.log("GetStopsForWindow got startAtMs: ", startAtMs);
    try {
      const stops = await apiCall(
        "post",
        `/stops/GetStopsForWindow`,
        { startAtMs: startAtMs, endAtMs: endAtMs },
        getState().currentUser
      );
      LOGGING &&
        console.log(
          "GetStopsForWindow got result from backend:",
          stops.filter((s) => s.stopType === "meal")
        );
      dispatch({
        type: LOAD_ADMIN_DELIVERY_V2_STOPS,
        stops,
      });
    } catch (err) {
      LOGGING && console.log("GetStopsForWindow got err", err);
    }
  };
};

export const Route = (isLunch) => {
  return async () => {
    // const { currentUser } = getState();
    LOGGING && console.log("Route called:", isLunch);
    try {
      const result = await apiCall("post", `/routes/CalcRoutesForMeal`, {
        isLunch,
      });
      LOGGING && console.log("Route got result from backend:", result);
      return "ok";
      // dispatch({
      //   type: LOAD_START,
      //   start,
      // });
    } catch (err) {
      LOGGING && console.log("GetPickupTime got err", err);
    }
  };
};

export const SetPickupTime = (windowStart, hour, minute) => {
  return async (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("SetPickupTime called", { windowStart, hour, minute });
    try {
      const start = await apiCall(
        "post",
        `/meals/routeStart/${windowStart}`,
        {
          hour,
          minute,
        },
        currentUser
      );
      LOGGING && console.log("SetPickupTime got from server", start);
      dispatch({
        type: LOAD_START,
        start,
      });
    } catch (err) {
      LOGGING && console.log("SetPickupTime got err", err);
    }
  };
};

export const getPickUpListForDay = () => {
  return async (dispatch, getState) => {
    LOGGING && console.log("getPickUpListForDay called");

    const now = moment().tz(CATimeZone);
    const [startAtMs, endAtMs] =
      now.hour() < 21 ? getWindowForDay() : getWindowForTomorrow();
    try {
      const restaurants = await apiCall(
        "post",
        `/stops/GetOrdersForWindow`,
        { startAtMs: startAtMs, endAtMs: endAtMs },
        getState().currentUser
      );
      LOGGING &&
        console.log(
          "GetOrdersForWindow got restaurants from backend:",
          restaurants
        );
      dispatch(LoadPickUpRestaurants(restaurants));
    } catch (err) {
      LOGGING && console.log("GetOrdersForWindow got err", err);
    }
  };
};

export const getRoutesForDay = () => {
  return async (dispatch, getState) => {
    LOGGING && console.log("getRoutesForDay called");

    const now = moment().tz(CATimeZone);
    const [startAtMs, endAtMs] =
      now.hour() < 21 ? getWindowForDay() : getWindowForTomorrow();
    try {
      const routes = await apiCall(
        "post",
        `/stops/GetRoutesForWindow`,
        { startAtMs: startAtMs, endAtMs: endAtMs },
        getState().currentUser
      );
      LOGGING &&
        console.log("GetRoutesForWindow got routes from backend:", routes);
      dispatch(LoadRoutes(routes));
    } catch (err) {
      LOGGING && console.log("GetRoutesForWindow got err", err);
    }
  };
};

export const getAdminDeliveryV2MealsForDay = () => {
  return async (dispatch, getState) => {
    LOGGING && console.log("GetMealsForWindow called");

    const now = moment().tz(CATimeZone);
    const [startAtMs, endAtMs] =
      now.hour() < 21 ? getWindowForDay() : getWindowForTomorrow();
    try {
      const meals = await apiCall(
        "post",
        `/meals/GetMealsForWindow`,
        { startAtMs: startAtMs, endAtMs: endAtMs },
        getState().currentUser
      );
      LOGGING &&
        console.log("GetMealsForWindow got result from backend:", meals);
      dispatch({
        type: LOAD_ADMIN_DELIVERY_V2_MEALS,
        meals,
      });
    } catch (err) {
      LOGGING && console.log("GetMealsForWindow got err", err);
    }
  };
};

// TODO: Move server calls for all functions out into a separate services and
// keep this file short and sweet
// NON-DISPATCH FUNCTIONS.

export const assignDriverToStop = (driverId, stopId) => {
  return async (dispatch, getState) => {
    LOGGING && console.log("AssignDriverToStop called");

    try {
      const stopIdsToRefresh = getState().adminDeliveryV2.stops.map(
        (s) => s._id
      );
      const refreshedStops = await apiCall(
        "post",
        "/stops/AssignDriverToStop",
        {
          driverId: driverId,
          stopId: stopId,
          stopIdsToRefresh: stopIdsToRefresh,
        },
        getState().currentUser
      );
      LOGGING &&
        console.log(
          "AssignDriverToStop got result from backend:",
          refreshedStops
        );

      const currentStops = getState().adminDeliveryV2.stops;

      dispatch({
        type: LOAD_ADMIN_DELIVERY_V2_STOPS,
        stops: combineArrays(currentStops, refreshedStops, (el) => el._id),
      });
    } catch (err) {
      LOGGING && console.log("AssignDriverToStop got err", err);
    }
  };
};

export const unassignDriverFromStop = (driverId, stopId) => {
  return async (dispatch, getState) => {
    LOGGING && console.log("UnassignDriverFromStop called");

    try {
      const stopIdsToRefresh = getState().adminDeliveryV2.stops.map(
        (s) => s._id
      );
      const refreshedStops = await apiCall(
        "post",
        "/stops/UnassignDriverFromStop",
        {
          driverId: driverId,
          stopId: stopId,
          stopIdsToRefresh: stopIdsToRefresh,
        },
        getState().currentUser
      );
      LOGGING &&
        console.log(
          "UnassignDriverFromStop got result from backend:",
          refreshedStops
        );

      const currentStops = getState().adminDeliveryV2.stops;

      dispatch({
        type: LOAD_ADMIN_DELIVERY_V2_STOPS,
        stops: combineArrays(currentStops, refreshedStops, (el) => el._id),
      });
    } catch (err) {
      LOGGING && console.log("UnassignDriverFromStop got err", err);
    }
  };
};

export const assignStopNum = (driverId, stopId, stopNum) => {
  return async (dispatch, getState) => {
    LOGGING && console.log("AssignStopNum called");

    try {
      const stopIdsToRefresh = getState().adminDeliveryV2.stops.map(
        (s) => s._id
      );
      const refreshedStops = await apiCall(
        "post",
        "/stops/AssignStopNum",
        {
          driverId: driverId,
          stopId: stopId,
          stopNum: stopNum,
          stopIdsToRefresh: stopIdsToRefresh,
        },
        getState().currentUser
      );
      LOGGING &&
        console.log("AssignStopNum got result from backend:", refreshedStops);

      const currentStops = getState().adminDeliveryV2.stops;

      dispatch({
        type: LOAD_ADMIN_DELIVERY_V2_STOPS,
        stops: combineArrays(currentStops, refreshedStops, (el) => el._id),
      });
    } catch (err) {
      LOGGING && console.log("AssignStopNum got err", err);
    }
  };
};

export const unassignStopNum = (driverId, stopId) => {
  return async (dispatch, getState) => {
    LOGGING && console.log("UnassignStopNum called");

    try {
      const stopIdsToRefresh = getState().adminDeliveryV2.stops.map(
        (s) => s._id
      );
      const refreshedStops = await apiCall(
        "post",
        "/stops/UnassignStopNum",
        {
          driverId: driverId,
          stopId: stopId,
          stopIdsToRefresh: stopIdsToRefresh,
        },
        getState().currentUser
      );
      LOGGING &&
        console.log("UnassignStopNum got result from backend:", refreshedStops);

      const currentStops = getState().adminDeliveryV2.stops;

      dispatch({
        type: LOAD_ADMIN_DELIVERY_V2_STOPS,
        stops: combineArrays(currentStops, refreshedStops, (el) => el._id),
      });
    } catch (err) {
      LOGGING && console.log("UnassignStopNum got err", err);
    }
  };
};

export const selectTransit = (stopId) => {
  return async (dispatch, getState) => {
    LOGGING && console.log("selectTransit called");

    try {
      const stopIdsToRefresh = getState().adminDeliveryV2.stops.map(
        (s) => s._id
      );
      const refreshedStops = await apiCall(
        "post",
        "/stops/SelectTransit",
        {
          stopId: stopId,
          stopIdsToRefresh: stopIdsToRefresh,
        },
        getState().currentUser
      );
      LOGGING &&
        console.log("selectTransit got result from backend:", refreshedStops);

      const currentStops = getState().adminDeliveryV2.stops;

      dispatch({
        type: LOAD_ADMIN_DELIVERY_V2_STOPS,
        stops: combineArrays(currentStops, refreshedStops, (el) => el._id),
      });
    } catch (err) {
      LOGGING && console.log("selectTransit got err", err);
    }
  };
};

export const deselectTransit = (stopId) => {
  return async (dispatch, getState) => {
    LOGGING && console.log("deselectTransit called");

    try {
      const stopIdsToRefresh = getState().adminDeliveryV2.stops.map(
        (s) => s._id
      );
      const refreshedStops = await apiCall(
        "post",
        "/stops/DeselectTransit",
        {
          stopId: stopId,
          stopIdsToRefresh: stopIdsToRefresh,
        },
        getState().currentUser
      );
      LOGGING &&
        console.log("deselectTransit got result from backend:", refreshedStops);

      const currentStops = getState().adminDeliveryV2.stops;

      dispatch({
        type: LOAD_ADMIN_DELIVERY_V2_STOPS,
        stops: combineArrays(currentStops, refreshedStops, (el) => el._id),
      });
    } catch (err) {
      LOGGING && console.log("deselectTransit got err", err);
    }
  };
};

export const calculateEtaForDriver = (driverId) => {
  return async (dispatch, getState) => {
    LOGGING && console.log("CalculateEtaForDriver called for:", { driverId });

    try {
      const stopIds = getState().adminDeliveryV2.stops.map((s) => s._id);
      const refreshedStops = await apiCall(
        "post",
        "/stops/CalculateEtaForDriver",
        {
          driverId: driverId,
          stopIds: stopIds,
        },
        getState().currentUser
      );
      LOGGING &&
        console.log(
          "CalculateEtaForDriver got result from backend:",
          refreshedStops
        );

      const currentStops = getState().adminDeliveryV2.stops;

      dispatch({
        type: LOAD_ADMIN_DELIVERY_V2_STOPS,
        stops: combineArrays(currentStops, refreshedStops, (el) => el._id),
      });
    } catch (err) {
      LOGGING && console.log("CalculateEtaForDriver got err", err);
    }
  };
};

export const cancelDriver = (driverId) => {
  return async (dispatch, getState) => {
    LOGGING && console.log("CancelDriver called");

    try {
      const stopIdsToRefresh = getState().adminDeliveryV2.stops.map(
        (s) => s._id
      );
      const result = await apiCall(
        "post",
        "/stops/CancelDriver",
        {
          driverId: driverId,
          stopIds: stopIdsToRefresh,
        },
        getState().currentUser
      );
      LOGGING && console.log("CancelDriver got result from backend:", result);

      const currentStops = getState().adminDeliveryV2.stops;
      dispatch({
        type: LOAD_ADMIN_DELIVERY_V2_STOPS,
        stops: combineArrays(currentStops, result.stops, (el) => el._id),
      });
      dispatch({
        type: LOAD_ADMIN_DELIVERY_V2_DRIVERS,
        drivers: combineArrays(
          getState().adminDeliveryV2.drivers,
          [result.driver],
          (el) => el._id
        ),
      });
    } catch (err) {
      LOGGING && console.log("CancelDriver got err", err);
    }
  };
};

export const createRoute = (driverId) => {
  return async (_dispatch, getState) => {
    LOGGING && console.log("CreateRoute called");

    try {
      const stopIds = getState().adminDeliveryV2.stops.map((s) => s._id);
      const route = await apiCall(
        "post",
        "/routes/CreateRoute",
        {
          driverId: driverId,
          stopIds: stopIds,
        },
        getState().currentUser
      );
      LOGGING && console.log("CreateRoute got result from backend:", route);
    } catch (err) {
      LOGGING && console.log("CreateRoute got err", err);
    }
  };
};

export const swapDrivers = (driverOneId, driverTwoId) => {
  return async (dispatch, getState) => {
    LOGGING && console.log("SwapDrivers called");

    try {
      const currentStops = getState().adminDeliveryV2.stops;
      const stopIds = currentStops.map((s) => s._id);
      const result = await apiCall(
        "post",
        "/drivers/SwapDrivers",
        {
          driverOneId: driverOneId,
          driverTwoId: driverTwoId,
          stopIds: stopIds,
        },
        getState().currentUser
      );
      LOGGING && console.log("SwapDrivers got result from backend:", result);
      dispatch({
        type: LOAD_ADMIN_DELIVERY_V2_STOPS,
        stops: combineArrays(currentStops, result.stops, (el) => el._id),
      });
      dispatch({
        type: LOAD_ADMIN_DELIVERY_V2_DRIVERS,
        drivers: combineArrays(
          getState().adminDeliveryV2.drivers,
          [result.driverOne, result.driverTwo],
          (el) => el._id
        ),
      });
    } catch (err) {
      LOGGING && console.log("SwapDrivers got err", err);
    }
  };
};

export const setIsLunch = (isLunch) => {
  return (dispatch) => {
    dispatch({
      type: ADMIN_DELIVERY_V2_SET_IS_LUNCH_FILTER,
      isLunch: isLunch,
    });
  };
};

export const setMealType = (mealType) => {
  return (dispatch) => {
    dispatch({
      type: ADMIN_DELIVERY_V2_SET_MEAL_TYPE_FILTER,
      mealType,
    });
  };
};
